

.video-responsive {
    overflow: hidden;
    height: 100vh;
}
  
.video-responsive iframe {
    height: 100vh;
    width: 150vh;
}

.involved-instrumentals-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(30px + 1vmin);
    font-family: Lato;
    padding: 2vh;
    background: black;
}

.involved-instrumentals-collabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: calc(30px + 1vmin);
    font-family: Lato;
    padding: 2vh;
    background: black;
    justify-content: space-around;
}

.involved-page {
    background: rgb(2,0,36);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(30px + 1vmin);
    font-family: Lato;
    color: white;
    padding-top:10vh;
}

.involved-content {
    margin: 2vh;
    background: white;
    min-height: 20vh;
    width: 50ch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(30px + 1vmin);
    font-family: Lato;
    color: black;
    border-radius: 10px;
    border: 3px solid #ff5c5c;
}

.involved-list {
    font-size: calc(20px + 1vmin);
    max-width: 65vw;
    text-align: left;
}

.involved-list li {
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.involved-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 6vh;
    background-color: black;
    color: white;
}

.involved-menu-item {
    font-size: calc(10px + 1vmin);
    width:33.33333%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 6vh;
    min-width: 10ch;
    background-color: black;
    color: white;
}

.involved-menu div:first-child {
    background-color: #ff5c5c;
}

.contact-input {
    width: 50vw;
    border: 0px;
    font-family: Lato;
    border-radius: 10px;
    font-size: calc(30px + 1vmin);
    padding: 1vh;
    margin-top: 3vh;
    margin-bottom: 3vh;
    text-align: center;
}

.contact-button{
    border: 0px;
    font-family: Lato;
    border-radius: 10px;
    font-size: calc(30px + 1vmin);
    padding: 1vh;
    margin-top: 3vh;
    margin-bottom: 3vh;
    background-color: #ff5c5c;
    color: white;
}