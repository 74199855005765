
.menu {
  position:absolute;
  top:0px;
  right:0px;
  width:400px;
  background: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(20px + 1vmin);
  font-family: Lato;
  color: black;
}

.menu-header {
  min-height: 10vh;
  width:100%;
  background-color: transparent ;
  opacity: 30;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  font-size: calc(10px + 1vmin);  
}

.menu-items {
  background: white;
  width: 100%;
}

.menu-item {
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}