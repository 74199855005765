
.App-header {
  background: black;
  background-size:cover;
  background-position: center;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: calc(30px + 1vmin);
  font-family: Lato;
  color: gold;
}

.header-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Lato;
}

.header-logo-00 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Lato;
}

.header-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  height:100%;
}

.header-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 10vh;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  justify-content: center;
  color: inherit;
}

.header-link a {
  text-decoration: none;
  color:inherit;
}

.header-link:hover {
  cursor: pointer;
  border: 1px;  
  border-color: white;
  background-color: white;
  color:black;
}

.header-tabs {
  background-color: white;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;    
  color: white;
  justify-content: center;
  font-family: inherit;
}

.header-tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  height: 5vh;
  min-width: 10vw;
  color: gold;
  background: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  letter-spacing: 2px;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  font-family: Lato;
}
  
.menu-button {
  cursor: pointer;
}

.header-menu-link {
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  color: inherit;
}

.header-tab:hover {
  background-color: #aaa;
  color: black;
}

.header-footer {
  background-color: gold;
  min-height: 0.5vh;
  min-width: 100%;
}