
.episode-latest {
    width: 100%;
}

.episodes {
    background: rgb(255, 255, 255);
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(30px + 1vmin);
    font-family: Lato;
    color: white;
    padding: 2vh;
}

.eps-episode {
    margin: 3vh;
    background: white;
    min-height: 16vh;
    min-width: 85vw;
    max-width: 85vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    font-size: calc(30px + 1vmin);
    font-family: Lato;
    color: black;
    border-radius: 10px;
}

.videoplayer {
    border: 0;
    width: 85vw;
    height: 790px;
}

.eps-episode-number {
    min-width: 15vw;
    font-size: calc(50px + 1vmin);
}

.eps-episode-thumbs {
    display: flex;
    flex-direction: row;
    background: yellow;
    height: 100%;
    min-width: 45vw;
    overflow: hidden;
}

.eps-episode-thumb {
    height:20vh;
    width:20vh;
}

.eps-episode-play {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 15vw;
}

,.eps-episode-play a {
    color: #ff5c5c;
}
.eps-episode-play a:hover {
    color: #ff5c5c;
}
.eps-episode-play a:visited {
    color: #ff5c5c;
}
.eps-episode-play a:active {
    color: #ff5c5c;
}
.episode-play a:focus {
    color: #ff5c5c;
}