
.App-footer {
  background: black;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 40px;
  font-family: Lato;
  color: white;
}

.footer-tabs {
  background-color: rgb(2,0,36);
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;    
  color: white;
  justify-content: center;
  font-family: Lato;
}

.footer-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Lato;
}

.footer-logo-00 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Lato;
}

.footer-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  font-family: Lato;
  color: white;
}

.footer-link {
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  color: inherit;
}

.footer-link a {
  text-decoration: none;
  color:white;
}

.footer-tab {
    color: white;
    background: linear-gradient(90deg, rgb(43, 54, 146) 0%, rgb(146 168 215) 100%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    letter-spacing: 2px;
    margin-left: 1vh;
    margin-right: 1vh;
}
  
  .footer-tabs1 {
    font-size: 2vh;
    height: 5vh;
    min-width: 33vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 2ch;
    justify-content: center;
  }
  
  .footer-tabs2 {
    font-size: 2vh;
    height: 5vh;
    min-width: 33vh;  
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 2ch;
    justify-content: center;
  }
  
  .footer-tabs3 {
    font-size: 2vh;
    height: 5vh;
    min-width: 33vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 2ch;
    justify-content: center;
  }
  
  .menu-button {
    cursor: pointer;
  }