
.item {
  display: flex;
  flex-direction: row;
  justify-content: left;
  font-size: calc(30px + 1vmin);
  background-color: black;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  margin: 3vh;
  min-width: 90vw;
  box-shadow: 8px 10px 2px 1px rgba(0, 0, 0, .2);
}

.item-spacer{
  height: 2vh;
}

.item-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width:45vw;
  padding:2.5vw;
}

.item-title {
  text-align: left;
  width: 100%;
  font-size: calc(30px + 1vmin);
}

.item-description {
  text-align: left;
  width: 100%;
  font-size: calc(16px + 1vmin);
}

.item-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(255,255,255,0.2);
  text-align: left;
  padding:1vh;
  font-size: calc(16px + 1vmin);
  color: gold;
}